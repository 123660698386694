html,
body {
  min-height: 100vh;
  background: #f0f2f5;
}
h1 {
  font-size: 1.5rem;
}
h2 {
  font-size: 1.2rem;
}
